import { useMemo } from 'react';

import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button } from 'components/Button';
import { Input } from 'components/form-partials/Input';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { posthogEvents } from 'constants/posthogEvents';
import { useIsNewCustomer } from 'hooks/useIsNewCustomer';
import { useMounted } from 'hooks/useMounted';
import { getValidationErrors } from 'hooks/useRequestError';
import { useAppDispatch, useAppSelector } from 'store';
import { useGetOrderPriceMutation } from 'store/api';
import type { AxiosBaseQueryError } from 'store/base';
import { toOrderConfigurationPayload } from 'store/orders/helpers';
import type { ProxySetupFormModel } from 'store/orders/models';
import { setCouponCode } from 'store/orders/reducers';

export type CouponCodeModalProps = {
  currentSettings?: ProxySetupFormModel;
};

type CouponCodeValues = {
  couponCode: string;
};

const schema: Yup.Schema<CouponCodeValues> = Yup.object().shape({
  couponCode: Yup.string().required(),
});

export const CouponCodeModal = ({ currentSettings }: CouponCodeModalProps) => {
  const { t } = useTranslation();
  const isNewCustomer = useIsNewCustomer();
  const hideModal = useHideModal();
  const { proxySettings, selectedNetworkType } = useAppSelector((state) => state.order);
  const isMounted = useMounted();
  const dispatch = useAppDispatch();
  const posthog = usePostHog();

  const [getOrderPrice] = useGetOrderPriceMutation();

  const values = useMemo(() => currentSettings ?? proxySettings, [currentSettings, proxySettings]);

  return (
    <Formik<CouponCodeValues>
      initialValues={{
        couponCode: '',
      }}
      onSubmit={async ({ couponCode }, { setSubmitting, setErrors }) => {
        try {
          const mappedValues = toOrderConfigurationPayload({
            ...{ ...values, networkType: selectedNetworkType },
            couponCode,
          });

          if (!mappedValues) return;

          await getOrderPrice(mappedValues).unwrap();

          if (!isMounted()) {
            return;
          }

          posthog?.capture(posthogEvents.order.codeApplied, { isNewCustomer });

          dispatch(setCouponCode(couponCode));

          hideModal();
        } catch (ex) {
          const validationErrors = getValidationErrors(ex as AxiosBaseQueryError);

          if (validationErrors && Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
          }

          posthog?.capture(posthogEvents.order.invalidCode, { isNewCustomer });

          setSubmitting(false);
        }
      }}
      validationSchema={schema}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <ModalContainer
          component="form"
          containerProps={{ onSubmit: handleSubmit, noValidate: true }}
          title={t('order.modals.coupon.title')}
          subtitle={t('order.modals.coupon.subtitle')}
          actions={[
            <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
              {t('common:buttons.cancel')}
            </Button>,
            <Button key="activate" type="submit" loading={isSubmitting} fullWidth disabled={!isValid}>
              {t('common:buttons.activate')}
            </Button>,
          ]}
        >
          <Input name="couponCode" placeholder={t('common:form.couponCode')} fullWidth />
        </ModalContainer>
      )}
    </Formik>
  );
};
